export default defineStore('RestaurantsStore', {
    state: () => ({
        restaurants: [],
        selectedRestaurantId: null,
        selectedRestaurant: {},
        setupRestaurant: {},
        hideRestaurantPicker: false,
    }),

    actions: {
        async createRestaurant(restaurant) {
            const { data, error } = await useFetch('/api/restaurants/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: restaurant,
            });

            if (error.value) {
                throw createError({
                    statusCode: error.value?.statusCode,
                    statusMessage: error.value?.message,
                });
            }

            this.setRestaurants([
                ...this.restaurants || [],
                data.value,
            ]);

            return data.value;
        },
        async uploadRestaurantLogo(logoFile) {
            const runtimeConfig = useRuntimeConfig();
            const apiURL = runtimeConfig.public.API_URL;
            const { useAuth } = useAuthEndpoints();
            const { session } = useAuth();
            const formData = new FormData();
            formData.append('file', logoFile.file);

            const { data, error } = await useFetch(`${apiURL}/s3-upload`, {
                method: 'POST',
                headers: {
                    'X-Tenant-Id': session.value?.tenantId,
                    Authorization: `Bearer ${session.value?.accessToken}`,
                },
                body: formData,
            });

            if (error.value) {
                throw createError({
                    statusCode: error.value?.statusCode,
                    statusMessage: error.value?.message,
                });
            }
            return data.value;
        },
        async updateRestaurant(restaurant) {
            const { id, ...updatedData } = restaurant;

            const { data, error } = await useFetch(`/api/restaurants/update/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: updatedData,
            });

            if (error.value) {
                throw createError({
                    statusCode: error.value?.statusCode,
                    statusMessage: error.value?.message,
                });
            }

            const updatedRestaurants = this.getRestaurants.map((rest) => {
                if (rest.id === id) {
                    return { ...rest, ...updatedData };
                }
                return rest;
            });

            this.setRestaurants(updatedRestaurants);

            if (this.selectedRestaurantId === id) {
                this.setSelectedRestaurant({
                    ...this.selectedRestaurant,
                    ...updatedData,
                });
            }
            return data.value;
        },
        async fetchRestaurants() {
            return $fetch('/api/restaurants/get-restaurants', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
        async fetchRestaurant(restaurantId) {
            return $fetch(`/api/restaurants/get-restaurant-details/${restaurantId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
        async fetchRestaurantDetails(restaurantId) {
            const { data, error } = await useFetch(`/api/restaurants/get-restaurant-details/${restaurantId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (error.value) {
                throw createError(error.value);
            }

            this.setSelectedRestaurant(data.value);
        },
        async generateMonthlyReport(date, session) {
            const { dayjs } = useDayJs();
            const selectedRestaurant = this.getSelectedRestaurant;
            const { name } = selectedRestaurant;
            const selectedMonth = dayjs(date).format('YYYY-MM-DD');
            const runtimeConfig = useRuntimeConfig();
            const apiURL = runtimeConfig.public.API_URL;
            let result = null;

            try {
                const config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    headers: {
                        Authorization: `Bearer ${session.accessToken}`,
                        'X-Tenant-Id': session.tenantId,
                        'Content-Type': 'application/json',
                    },
                    body: {
                        tenantName: session.tenantName,
                        restaurantName: name,
                        month: selectedMonth,
                    },
                };

                result = await $fetch(`${apiURL}/monthly-report/export`, config);
            } catch (e) {
                useSentryError(e);
            }

            return result;
        },
        async closeMonth(date, session) {
            const { dayjs } = useDayJs();
            const selectedRestaurant = this.getSelectedRestaurant;
            const { name } = selectedRestaurant;
            const selectedMonth = dayjs(date).format('YYYY-MM-DD');
            const runtimeConfig = useRuntimeConfig();
            const apiURL = runtimeConfig.public.API_URL;
            let result = null;

            try {
                const config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    headers: {
                        Authorization: `Bearer ${session.accessToken}`,
                        'X-Tenant-Id': session.tenantId,
                        'Content-Type': 'application/json',
                    },
                    body: {
                        tenantName: session.tenantName,
                        restaurantName: name,
                        month: selectedMonth,
                    },
                };

                result = await $fetch(`${apiURL}/monthly-report/close`, config);
            } catch (e) {
                useSentryError(e);
            }

            return result;
        },
        async monthIsClosed(date, session) {
            const selectedRestaurant = this.getSelectedRestaurant;
            const { name } = selectedRestaurant;
            const { dayjs } = useDayJs();
            const selectedMonth = dayjs(date).format('YYYY-MM-DD');
            const runtimeConfig = useRuntimeConfig();
            const apiURL = runtimeConfig.public.API_URL;
            let result = null;

            try {
                const config = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${session.accessToken}`,
                        'X-Tenant-Id': session.tenantId,
                        'Content-Type': 'application/json',
                    },
                };

                result = await $fetch(`${apiURL}/monthly-report/isClosed/${selectedMonth}/${name}`, config);
            } catch (e) {
                useSentryError(e);
            }

            return result;
        },
        setRestaurants(restaurants) {
            this.restaurants = restaurants;
        },
        setSelectedRestaurantId(restaurantId) {
            this.selectedRestaurantId = restaurantId;
            this.fetchRestaurantDetails(restaurantId);
        },
        setSelectedRestaurant(restaurant) {
            this.selectedRestaurant = restaurant;
        },
        setSetupRestaurant(restaurant) {
            this.setupRestaurant = restaurant;
        },
        setHideRestaurantPicker(hide) {
            this.hideRestaurantPicker = hide;
        },
    },

    getters: {
        getRestaurants(state) {
            return state.restaurants;
        },
        getSelectedRestaurant(state) {
            return state.selectedRestaurant;
        },
        getSelectedRestaurantId(state) {
            return state.selectedRestaurantId;
        },
        getSetupRestaurant(state) {
            return state.setupRestaurant;
        },
        getHideRestaurantPicker(state) {
            return state.hideRestaurantPicker;
        },
    },
});
